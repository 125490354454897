/**
 * StagesController.ts
 *
 * Desc: Stage controller, ensure to have one way to get data
 */
import { stagesRepository } from '../infrastracture/api/StageRepository';
import { StagesService } from '../domain/services/StagesService';
import type { Stage } from '../domain/models/types/Stage';
import type { RefusedReason } from '../../../models/RefusedReason';
import type {Error} from "../../error/domain/models/types/Error";
import {DefaultError} from "../../../constants/Errors";
import {navigate} from "svelte-navigator";

const stagesService = new StagesService(stagesRepository);

/**
 * function to load stages into the session storage
 * return stages
 */
export const loadStages = async (): Promise<Stage[]> => {
  let stages = sessionStorage.getItem('stages');
  if (!stages) {
    try {
      stages = JSON.stringify(await stagesService.getStages());
      sessionStorage.setItem('stages', stages);

    } catch (e) {
      // if we send only {state: { error: e }} we loose e.response.data which are our custom errors from the back
      const error: Error = (
          e.response.data.status? e.response.data: DefaultError.response.data
      )
      navigate('/err', {state: {error: {response: {data: error}}}})
      throw e
    }
  }
  return JSON.parse(stages);
};

/**
 * function to get stages from the session storage
 * if session storage is empty then load the stages
 */
export const getStages = async (): Promise<Stage[]> => {
  let stages = sessionStorage.getItem('stages');

  if (!stages) return await loadStages();

  return JSON.parse(stages);
};

/**
 * function to get refused reasons from the refused stage
 */
export const getRefusedReasons = async (): Promise<RefusedReason[] | undefined> => {
  const stages = await getStages();

  return stages.filter((stage) => {
    return stage.name === 'Refused';
  })[0].refusedReasons;
};
