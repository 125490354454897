/**
 * UserService.ts
 *
 * Desc: service of API
 * Authors: Nicolas Galois, Dmytro Teliakovskyi
 */

import type { User } from '../models/types/User';
import type { UserRepositoryInterface } from '../models/interfaces/UserRepositoryInterface';

export class UserService {
  constructor(protected userRepository: UserRepositoryInterface) {}

  /**
   * get companyId and contactId from API
   * @param email
   */
  async getUserInfo(email: string): Promise<User> {
    return await this.userRepository.getUserInfo(email);
  }
}
