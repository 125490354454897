<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  // Components
  import Button from '../../../../../lib/Button.svelte';
  import Modal from '../../../../../lib/Modal.svelte';
  import FaExclamationCircle from 'svelte-icons/fa/FaExclamationCircle.svelte';
  import { getRefusedReasons } from '../../../application/StagesController';
  import type { RefusedReason } from '../../../../../models/RefusedReason';
  // Imported variables
  export let handleRequest: Function;
  export let refused: boolean = false;

  const promiseReasons = getRefusedReasons() as Promise<RefusedReason[]>;

  let reason: RefusedReason;
  let note: string;
  let selected: boolean;

  const dispatch = createEventDispatcher();

  const submit = () => {
    if (reason) {
      dispatch('closeModal', { data: false });
      handleRequest(note, undefined, reason.Value);
    } else {
      selected = true;
    }
  };

  $: if (reason) {
    selected = false;
  }
</script>

<Modal isOpen={refused}>
  <form id="refusedModal">
    <main>
      <aside>
        <i>
          <FaExclamationCircle />
        </i>
      </aside>
      <aside>
        <header>
          <h2>Candidate does not correspond to your expectations?</h2>
          <h4>To confirm it, please fill the information below</h4>
        </header>
        <section>
          <div>
            <label for="reasons">Reason</label>
            <select id="reasons" bind:value={reason} class:selected>
              <option value="" disabled>Please select the reason </option>
              {#await promiseReasons then reasons}
                {#each reasons as reason (reason.Value)}
                  <option value={reason}>{reason.Description}</option>
                {/each}
              {/await}
            </select>
          </div>
          <div>
            <label for="notes">Notes</label>
            <textarea form="refusedModal" id="notes" bind:value={note} rows="4" cols="42" />
          </div>
        </section>
      </aside>
    </main>
    <footer>
      <Button
        type={'cancel'}
        on:click={() => {
          dispatch('closeModal', { data: false });
        }}
        >Cancel
      </Button>
      <Button on:click={submit}>Send</Button>
    </footer>
  </form>
</Modal>

<style lang="scss">
  form {
    display: flex;
    flex-direction: column;
  }

  main {
    display: flex;
    gap: 2rem;
    padding: 2rem 4rem;

    @media only screen and (max-width: 36em) {
      gap: 1rem;
    }
  }

  aside {
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  i {
    margin-top: 1rem;
    display: block;
    width: 4rem;
    height: 4rem;
  }
  .selected {
    border: 1px solid #ff6239;
  }
  header {
    display: flex;
    flex-direction: column;
    white-space: nowrap;

    @media only screen and (max-width: 36em) {
      white-space: normal;
    }
  }

  h2 {
    font-size: 2.2rem;
  }

  h4 {
    font-size: 1.8rem;
    font-weight: 300;
  }

  section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;
  }

  div {
    display: flex;
    align-items: flex-start;
  }

  label {
    flex: 0 0 15%;
    font-size: 1.6rem;
  }

  select {
    display: inline-block;
    padding: 0.5rem;
    flex: 0 0 80%;
    max-width: 80%;
    outline: none;
    border: 1px solid #696060;
    border-radius: 3px;
    color: inherit;
    background: inherit;
    font-size: 1.4rem;

    &:focus {
      outline: none;
      border: 1px solid #37f489;
    }
  }

  option {
    text-overflow: ellipsis;
  }

  textarea {
    font-family: inherit;
    color: inherit;
    display: inline-block;
    padding: 0.5rem;
    height: 8rem;
    flex: 0 0 80%;
    resize: vertical;
    border-radius: 3px;
    font-size: 1.4rem;

    &:focus {
      outline: none;
      border: 1px solid #37f489;
    }
  }

  footer {
    padding: 2rem 4rem;
    background: #f8f8f8;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    border-top: 1px solid #e8e8e8;
  }
</style>
