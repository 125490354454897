import {ApiRepository} from '../../../../helpers/ApiRepository';
import type {Stage} from "../../domain/models/types/Stage";
import type {StagesInterface} from "../../domain/models/interfaces/StagesInterface";

class StagesRepository implements StagesInterface {
    async getStages(): Promise<Stage[]> {
        try {
            const {data} = await ApiRepository.get(
                `/stages`
            );
            return data;
        } catch (err) {
            throw err;
        }
    }
}

export const stagesRepository = new StagesRepository();
