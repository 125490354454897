<script lang="ts">
  import { timeSince } from '../../application/NeedDisplayHelper';
  // Types
  import type { NeedList } from '../../domain/models/types/NeedList';
  // Components
  import ProgressBar from './ProgressBar.svelte';
  // Imported variables
  export let need: NeedList;
  // Local variables
  const maxCount = need.statuses.reduce((acc, status) => (status.count < acc ? acc : status.count), 0);
  const lastUpdate = Date.parse(need.lastUpdate);
  const displayDateText = timeSince(lastUpdate);
</script>

<article>
  <header>
    <h4>{need.title}</h4>
  </header>
  <div class={need.type.toLocaleLowerCase()}><span class="type">{need.type}</span></div>
  <section>
    {#each need.statuses as status (status.code)}
      <ProgressBar bind:status {maxCount} />
    {/each}
  </section>
  <footer>
    {#if need.lastUpdate}
      <span>Last Modification: ⏱{displayDateText}</span>
    {/if}
  </footer>
</article>

<style lang="scss">
  article {
    position: relative;
    min-height: 37rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    justify-content: center;
    padding: 2rem 4rem;
    background: #f8f8f8;
    border-radius: 1rem;
    transition: all 0.2s;
    border: 3px solid transparent;

    &:hover {
      box-shadow: 0 3px 10px #c5c5c5;
    }

    &:active {
      box-shadow: 0 1px 3px #c5c5c5;
    }
  }

  header {
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(#696060, 0.1);
  }

  h4 {
    font-size: 2.4rem;
    font-weight: 500;
  }

  section {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    justify-content: center;
  }

  span {
    font-size: 1.4rem;
  }
  .subscription,
  .permanent,
  .outsourcing {
    height: 7rem;
    width: 7rem;
    position: absolute;
    top: -0.8%;
    right: -0.8%;
    padding: 1rem;
    border-top-right-radius: 1rem;
    clip-path: polygon(100% 0, 0 0, 100% 100%);
  }

  .subscription {
    background: #37f487;
  }

  .permanent {
    background: #3fb7f1;
  }

  .outsourcing {
    background: #ffa339;
  }

  .type {
    position: absolute;
    top: 2.1rem;
    right: -0.6rem;
    display: flex;
    color: #fdfefe;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1rem;
    transform: rotate(45deg);
  }
  footer {
    display: flex;
    justify-content: flex-end;
  }
</style>
