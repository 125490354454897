<script lang="ts">
  import { Link } from 'svelte-navigator';
  // Components
  import FaFolderOpen from 'svelte-icons/fa/FaFolderOpen.svelte';
  import Wrapper from '../../../../lib/Wrapper.svelte';
  import Spinner from '../../../../lib/Spinner.svelte';
  import NeedCard from '../components/NeedCard.svelte';
  // Application
  import { loadNeedList } from '../../application/NeedListController';
  // Store
  import { needListStore } from '../../infrastructure/store/NeedListStore';
  import { filteredNeedStore } from '../../../header/infrastructure/filteredNeedStore';
  import { getMsalUser } from '../../../authentication/application/MsalController';
  import { getUserInfo } from '../../../authentication/application/UserController';

  const promiseNeedList = loadNeedList();

  const promiseName = getMsalUser().then((msalUser) => {
    return msalUser.account.name;
  });

  const promiseContactMailBox = getMsalUser()
    .then((msalUser) => getUserInfo(msalUser.account.username))
    .then((user) => user.contactMailBox);
</script>

<Wrapper>
  {#await promiseName}
    <Spinner />
  {:then name}
    <header>
      <h1>Welcome {name} on your Dashboard 🚀</h1>
    </header>
  {/await}
  {#await promiseNeedList}
    <Spinner />
  {:then needListIstStored}
    {#if $needListStore.length >= 1}
      <section class="dashboard">
        {#each $filteredNeedStore as need (need)}
          <Link to="/need/{need.id}">
            <NeedCard {need} />
          </Link>
        {/each}
      </section>
    {:else}
      <!-- No project on dashboard -->
      <div>
        <h3>No projects yet</h3>
        <i>
          <FaFolderOpen />
        </i>
        <p>
          If it’s an error, warn your Bertek contact or send an email <br />
          at 👉
          {#await promiseContactMailBox then contactMailBox}
            {contactMailBox}
          {/await}
        </p>
      </div>
    {/if}
  {/await}
</Wrapper>

<style lang="scss">
  header {
    padding: 4rem;
    display: flex;
    justify-content: center;
  }

  section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(36rem, 42rem));
    justify-content: center;
    gap: 2rem;
    padding: 1rem;

    // Styles for Link Svelte build in component
    & > :global(a) {
      color: inherit;
      text-decoration: none;
    }
  }

  div {
    margin-top: 8rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }

  h1 {
    text-align: center;
    font-size: 4rem;
    font-weight: 300;
  }

  h3 {
    font-size: 3.2rem;
    font-weight: 400;
  }

  p {
    text-align: center;
    font-size: 2rem;
  }

  i {
    display: block;
    height: 12rem;
    width: 12rem;
    transition: all 0.2s;

    &:hover {
      color: #36f777;
      animation: jelly 0.5s 1;
    }
  }

  @keyframes jelly {
    from,
    to {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
    25% {
      -webkit-transform: scale(0.95, 1.01);
      transform: scale(0.95, 1.01);
    }
    50% {
      -webkit-transform: scale(1.01, 0.95);
      transform: scale(1.01, 0.95);
    }
    75% {
      -webkit-transform: scale(0.99, 1.09);
      transform: scale(0.99, 1.01);
    }
  }
</style>
