<script lang="ts">
  import { Link } from 'svelte-navigator';
  // Images
  import { bertekLogo } from '../../../../constants/images';
  // Components
  import Logout from '../../../authentication/presentation/page/Logout.svelte';
  import SearchBar from '../components/SearchBar.svelte';
  // Imported variables
  export let showBar: boolean = false;
</script>

<header>
  <Link to="/">
    <img src={bertekLogo} alt="Bertek logo" />
  </Link>
  {#if showBar}
    <SearchBar />
  {/if}
  {#if sessionStorage.length > 1}
    <Logout />
  {/if}
</header>

<style lang="scss">
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.4rem;
    box-shadow: 1px 1px 2px#c5c5c5;
  }
  img {
    height: 3.6rem;
    margin-left: 2rem;

    &:hover {
      animation: jelly 0.5s 1;
    }
  }

  @keyframes jelly {
    from,
    to {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
    25% {
      -webkit-transform: scale(0.95, 1.01);
      transform: scale(0.95, 1.01);
    }
    50% {
      -webkit-transform: scale(1.01, 0.95);
      transform: scale(1.01, 0.95);
    }
    75% {
      -webkit-transform: scale(0.99, 1.09);
      transform: scale(0.99, 1.01);
    }
  }
</style>
