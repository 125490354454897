/**
 * MsalRepository.ts
 *
 * Desc: Implementation of service from microsoft
 *
 */

import * as Msal from "@azure/msal-browser";
import {accessRefreshTokenRequest, loginRequest, logoutRequest, msalConfig} from "./MsalConfig";
import type {MsalRepositoryInterface} from "../domain/models/interfaces/MsalRepositoryInterface";

export class MsalRepository implements MsalRepositoryInterface{
    /**
     * Msal Instance with configuration parameters for MSAL
     */
    private msalInstance = new Msal.PublicClientApplication(msalConfig);

    /**
     * Function to login and get user data from MSAL by popup
     */
    async getUser() {
        let user;

        try {
            user = await this.msalInstance.loginPopup(loginRequest);
        } catch (err) {
            throw err;
        }

        return user;
    }

    /**
     * Function to logout from MSAL
     */
    async logout() {
        try {
            await this.msalInstance.logoutRedirect(logoutRequest(this.msalInstance));
        } catch (error) {
            throw error;
        }
    }

    /**
     * Function to get the refresh token
     */
    async getRefreshToken() {
        const authResult = await this.msalInstance.acquireTokenSilent(
            accessRefreshTokenRequest(this.msalInstance.getAllAccounts()[0])
        );
        return authResult.idToken;
    }
}

export const msalRepository = new MsalRepository();
