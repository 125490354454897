<script lang="ts">
  export let type: string = 'primary';
</script>

<button class={type} on:click|preventDefault>
  <slot />
</button>

<style lang="scss">
  .primary {
    background: #37f489;

    &--outline {
      color: #37f489;
      border: 1px solid #37f489;
    }
  }
  .secondary {
    background: #3fb7f1;

    &--outline {
      color: #3fb7f1;
      border: 1px solid #3fb7f1;
    }
  }
  .cancel {
    background: #ff6239;

    &--outline {
      color: #ff6239;
      border: 1px solid #ff6239;
    }
  }
  button {
    cursor: pointer;
    padding: 0.6rem 1.2rem;
    border-radius: 0.5rem;
    border: 1px solid transparent;
    color: #fdfefe;
    background: #fdfefe;
    font-size: 1.4rem;
    font-weight: bold;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);

    &:hover,
    &:focus {
      outline: none;
      -webkit-animation: hover 600ms linear alternate;
      animation: hover 600ms linear alternate;
    }

    &:active {
      -webkit-animation: active 600ms ease alternate;
      animation: active 600ms ease alternate;
    }

    @keyframes active {
      0% {
        transform: scale(1, 1);
      }
      90% {
        transform: scale(0.9, 0.88);
      }
      100% {
        transform: scale(0.92, 0.9);
      }
    }
    @keyframes hover {
      0%,
      100% {
        transform: scale(1, 1);
      }
      25% {
        transform: scale(0.9, 1.1);
      }
      50% {
        transform: scale(1.1, 0.9);
      }
      75% {
        transform: scale(0.95, 1.05);
      }
    }
  }
</style>
