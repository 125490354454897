import type { ApplicationInterface } from '../models/interfaces/ApplicationInterface';
import type {ApplicationUpdate as Application} from "../models/types/Application";

export class ApplicationService {
  constructor(protected applicationRepository: ApplicationInterface) {}

  update(
    applicationId: string,
    application: Application
  ) {
    return this.applicationRepository.update(applicationId, application);
  }


  getStages() {
    return this.applicationRepository.getStages()
  }
}
