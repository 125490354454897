/**
 * UserController.ts
 *
 * Desc: controller of user
 */

import {UserService} from '../domain/services/UserService';
import {userRepository} from '../infrastructure/api/UserRepository';
import type {User} from "../domain/models/types/User";
import {getMsalUser} from "./MsalController";
import {loadStages} from "../../need/application/StagesController";
import {navigate} from "svelte-navigator";

const userService = new UserService(userRepository);

/**
 * Function for login and set
 * the user info to the session storage
 */
export async function login() {
    try {
        // set user info in session storage
        await loadUserInfo();
        // Set board names into session storage after getting account from msal to be able to have the token
        loadStages().then()
        navigate('/')

    } catch (e) {
        navigate('/err', {state: {error: {response: {data: e.response.data}}}})
    }
}

/**
 * function to load user from microsoft and from api
 */
export async function loadUserInfo() {
    const msalUser = await getMsalUser()

    try {
        // Get companyId, contactId and contactMailBox depending on the country of the client from API
        await getUserInfo(msalUser.account.username)
    } catch (e) {
        throw e
    }
}

/**
 * function to get user info and set it into the session storage
 */
export async function getUserInfo(email: string): Promise<User> {
    const userData = sessionStorage.getItem("user")

    if(!userData) {
        const user = await userService.getUserInfo(email)
        sessionStorage.setItem("user", JSON.stringify(user))
        return user
    }

    return JSON.parse(userData);
}
