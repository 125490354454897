<script lang="ts">
  // Components
  import MdSearch from 'svelte-icons/md/MdSearch.svelte';
  // Application
  import { handleSearchChanged } from '../../application/FilterController';

  let searchTerm = '';

  $: {
    handleSearchChanged(searchTerm);
  }
</script>

<form>
  <input bind:value={searchTerm} placeholder="Search project" />
  <button>
    <i>
      <MdSearch />
    </i>
  </button>
</form>

<style lang="scss">
  form {
    flex: 0 0 30%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input {
    font-family: inherit;
    font-size: inherit;
    height: 3.5rem;
    border: 1px solid #d1d1d1;
    padding: 0.7rem 2rem;
    border-radius: 0.5rem;
    margin-right: -3.25rem;
    transition: all 0.2s;

    &:focus,
    &:active {
      flex: 1;
      outline: none;
    }

    &::placeholder {
      font-weight: 100;
    }
  }
  button {
    height: 2rem;
    width: 2rem;
    cursor: pointer;
    background-color: inherit;
    border: none;
  }
  i {
    color: #808080;
    height: 2rem;
    width: 2rem;
  }
</style>
