/**
 * UserRepository.ts
 *
 * Desc: implementation of interface user API
 */

// Interfaces
import type { UserRepositoryInterface } from '../../domain/models/interfaces/UserRepositoryInterface';
import { ApiRepository } from '../../../../helpers/ApiRepository';
import type {User} from "../../domain/models/types/User";

/**
 * Msal Repository instance of MSAL
 */
export class UserRepository implements UserRepositoryInterface {
  /**
   *  Function for getting company Id
   */
  async getUserInfo(email: string): Promise<User> {
    try {
      const { data } = await ApiRepository.get(`/auth?email=${email}`);
      return data;
    } catch (err) {
      throw err;
    }
  }
}

export const userRepository = new UserRepository();
