<script lang="ts">
    // Application
    import {loadNeed, handleCandidateMoved} from '../../application/NeedController';
    // Components
    import Wrapper from '../../../../lib/Wrapper.svelte';
    import Spinner from '../../../../lib/Spinner.svelte';
    import Header from '../components/Header.svelte';
    import Board from '../components/Board.svelte';
    import InterviewModal from '../components/modals/InterviewModal.svelte';
    import AcceptModal from '../components/modals/AcceptModal.svelte';
    import RefusedModal from '../components/modals/RefusedModal.svelte';
    // Imported variables
    export let needId: string = '';

    // Modal variable isOpen
    let interview = false;
    let accept = false;
    let refused = false;

    // variable to save the drag and dropped candidate, they might be removed at some point
    let droppedBoard;
    let candidateMoved;
    /**
     * function called after candidateMoved event triggered in board
     * @param event
     */
    function candidateMove(event: CustomEvent) {
        // Save candidate movement
        const {droppedBoard: board, application} = event.detail.data;

        candidateMoved = application;
        droppedBoard = board;
    }

    /**
     * function called to request the api from the modal and get back application board updated
     */
    async function handleRequest(note?: string, availability?: string, refusedReasonCode?: string) {
        handleCandidateMoved(candidateMoved, droppedBoard, note, availability, refusedReasonCode).then();
    }

    /**
     * function to handle isOpen of interview modal
     * @param event
     */
    function handleInterviewModal(event: CustomEvent) {
        interview = event.detail.data;
    }

    /**
     * function to handle isOpen of accepted modal
     * @param event
     */
    function handleAcceptModal(event: CustomEvent) {
        accept = event.detail.data;
    }

    /**
     * function to handle isOpen of refused modal
     * @param event
     */
    function handleRefusedModal(event: CustomEvent) {
        refused = event.detail.data;
    }


</script>

<Wrapper>
    {#await loadNeed(needId)}
        <Spinner/>
    {:then currentNeedIsStored}
        {#if interview}
            <section class="backdrop" on:click={() => (interview = false)}></section>
            <InterviewModal {interview} on:closeModal={handleInterviewModal} {handleRequest}/>
        {/if}

        {#if accept}
            <section class="backdrop" on:click={() => (accept = false)}></section>
            <AcceptModal {accept} on:closeModal={handleAcceptModal} {handleRequest}/>
        {/if}

        {#if refused}
            <section class="backdrop" on:click={() => (refused = false)}></section>
            <RefusedModal {refused} on:closeModal={handleRefusedModal} {handleRequest}/>
        {/if}

        <Header />
        <Board
                on:candidateMoved={candidateMove}
                on:showModalInterviewed={handleInterviewModal}
                on:showModalAccepted={handleAcceptModal}
                on:showModalRefused={handleRefusedModal}
                on:showNoModal={() => handleRequest()}
        />
    {/await}
</Wrapper>

<style lang="scss">
  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.4);
  }
</style>
