<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  // Components
  import Modal from '../../../../../lib/Modal.svelte';
  import Button from '../../../../../lib/Button.svelte';
  import FaCheckCircle from 'svelte-icons/fa/FaCheckCircle.svelte';
  // Imported variables
  export let handleRequest: Function;
  export let accept: boolean = false;

  let note: string;

  const dispatch = createEventDispatcher();

  const submit = () => {
    dispatch('closeModal', { data: false });
    handleRequest(note);
  };
</script>

<Modal isOpen={accept}>
  <form id="acceptModal">
    <main>
      <aside>
        <i>
          <FaCheckCircle />
        </i>
      </aside>
      <aside>
        <header>
          <h2>Candidate passed successfully the interview</h2>
          <h4>To confirm it, please fill the information below</h4>
        </header>
        <section>
          <label for="notes">Notes</label>
          <textarea form="acceptModal" id="notes" bind:value={note} rows="4" cols="42" />
        </section>
      </aside>
    </main>

    <footer>
      <Button
        type={'cancel'}
        on:click={() => {
          dispatch('closeModal', { data: false });
        }}>Cancel</Button
      >
      <Button on:click={submit}>Send</Button>
    </footer>
  </form>
</Modal>

<style lang="scss">
  form {
    display: flex;
    flex-direction: column;
  }
  main {
    display: flex;
    gap: 2rem;
    padding: 2rem 4rem;

    @media only screen and (max-width: 36em) {
      gap: 1rem;
    }
  }

  aside {
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  i {
    margin-top: 1rem;
    display: block;
    width: 4rem;
    height: 4rem;
  }

  header {
    display: flex;
    flex-direction: column;
    white-space: nowrap;

    @media only screen and (max-width: 36em) {
      white-space: normal;
    }
  }

  h2 {
    font-size: 2.2rem;
  }

  h4 {
    font-weight: 300;
    font-size: 1.8rem;
  }

  section {
    display: flex;
    padding: 1rem 0;
  }

  label {
    flex: 0 0 15%;
    font-size: 1.6rem;
  }

  textarea {
    font-family: inherit;
    color: inherit;
    display: inline-block;
    padding: 0.5rem;
    height: 8rem;
    flex: 0 0 75%;
    resize: vertical;
    border-radius: 3px;
    font-size: 1.4rem;

    &:focus {
      outline: none;
      border: 1px solid #37f489;
    }
  }

  footer {
    padding: 2rem 4rem;
    background: #f8f8f8;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    border-top: 1px solid #e8e8e8;
  }
</style>
