/**
 * ApiRepository.ts
 *
 * Refactored class to request the back with axios
 *
 * Authors: Nicolas Galois, Dmytro Teliakovskyi
 */

import axios from 'axios';
import type { AxiosRequestConfig } from 'axios';
import {getIdToken} from "../modules/authentication/application/MsalController";

// Api Repository for making requests to the server
export class ApiRepository {
  /**
   * Function for initialising GET request options
   * @param url
   * @private
   */
  private static async initGetOptions(url: string): Promise<AxiosRequestConfig> {
    return {
      method: 'GET',
      url: global.APP_API_URL + url,
      headers: {
        Authorization: `Bearer ${await getIdToken()}`,
      },
    };
  }

  /**
   * GET request to the server
   * @param url
   */
  static async get(url: string) {
    const options = await this.initGetOptions(url);
    return axios(options);
  }

  /**
   * Function for initialising POST request options
   * @param url
   * @param body
   */
  private static async initPostOptions(url: string, body: any): Promise<AxiosRequestConfig> {
    return {
      method: 'POST',
      url: global.APP_API_URL + url,
      headers: {
        Authorization: `Bearer ${await getIdToken()}`,
      },
      data: body,
    };
  }

  /**
   * POST request to the server
   * @param url
   * @param body
   */
  static async post(url: string, body: any) {
    const options = await this.initPostOptions(url, body);
    return axios(options);
  }
}
