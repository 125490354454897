<script lang="ts">
  import type { Error } from '../../domain/models/types/Error';
  import { displayMessage, displayStatus } from '../../application/ErrorDisplayHelper';
  import { errorImage } from '../../../../constants/images';
  import FaFolderOpen from 'svelte-icons/fa/FaFolderOpen.svelte';

  /**
   * This variable is overridden when we are able to pass error to the component through state
   * when we navigate to /err, we have this variable overridden by navigate('/err', {state: {error: {response: {data: e.response.data}}}})
   */
  export let error: Error = {
    response: {
      data: {
        status: '404 Not Found',
        message:
          "Sorry we couldn't find the page you were looking for, inform your Bertek contact or send an email at ${contactMailBox} if you need any help",
      },
    },
  };
</script>

<section>
  <aside>
    {#if error.response.data.notFound}
      <i>
        <FaFolderOpen />
      </i>
    {:else}
      <picture>
        <img src={errorImage} alt="Error cloud" />
      </picture>
    {/if}
    <h1>
      {displayStatus(error)}
    </h1>
    <h3>
      {#await displayMessage(error) then displayedMessage}
        {displayedMessage}
      {/await}
    </h3>
  </aside>
</section>

<style lang="scss">
  section {
    height: 75vh;
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    color: inherit;
  }

  aside {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 84rem;
    text-align: center;
  }

  h1 {
    font-size: 3.6rem;
    font-weight: bold;
  }

  i {
    display: block;
    height: 14rem;
    width: 14rem;
    transition: all 0.2s;
  }

  h3 {
    font-size: 2.4rem;
    font-weight: 300;
  }

  picture {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35rem;
    padding-bottom: 3rem;
    animation: cloud 5s ease-in-out infinite;
    box-shadow: #ffffff 6.5rem -1.5rem 0 -0.5rem, #ffffff 2.5rem -2.5rem, #ffffff 3rem 1rem, #ffffff 6rem 1.5rem 0 -1rem,
      #ffffff 8.5rem 0.5rem 0 -0.5rem;

    &:before {
      position: absolute;
      top: 15rem;
      left: 10rem;
      animation: rain 0.7s infinite linear;
      content: '';
      background: #cccccc;
      border-radius: 50%;
      display: block;
      height: 6px;
      width: 3px;
      opacity: 0.3;
    }

    &:after {
      position: absolute;
      bottom: -17rem;
      background: #000000;
      border-radius: 50%;
      content: '';
      height: 1.5rem;
      width: 25rem;
      opacity: 0.2;
      animation: shadow 5s ease-in-out infinite;
    }
  }

  img {
    height: 100%;
    width: 100%;
  }

  @keyframes cloud {
    50% {
      transform: translateY(-2rem);
    }
  }

  @keyframes shadow {
    50% {
      transform: translateY(2rem) scale(0.7);
      opacity: 0.05;
    }
  }

  @keyframes rain {
    0% {
      box-shadow: #000 3rem 3rem, #000 4rem 4rem, #000 5rem 7.5rem, #000 5.5rem 5rem, #000 7rem 10rem, #000 8rem 9.5rem,
        #000 11rem 4.5rem, #000 9rem 3.5rem;
    }
    25% {
      box-shadow: #000 30px 45px, #000 40px 60px, #000 50px 90px, #000 55px 65px, rgba(0, 0, 0, 0) 70px 120px,
        rgba(0, 0, 0, 0) 80px 120px, #000 110px 70px, #000 90px 60px;
    }
    26% {
      box-shadow: #000 30px 45px, #000 40px 60px, #000 50px 90px, #000 55px 65px, rgba(0, 0, 0, 0) 70px 40px,
        rgba(0, 0, 0, 0) 80px 20px, #000 110px 70px, #000 90px 60px;
    }
    50% {
      box-shadow: #000 30px 70px, #000 40px 80px, rgba(0, 0, 0, 0) 50px 100px, #000 55px 80px, #000 70px 60px,
        #000 80px 45px, #000 110px 95px, #000 90px 85px;
    }
    51% {
      box-shadow: #000 30px 70px, #000 40px 80px, rgba(0, 0, 0, 0) 50px 45px, #000 55px 80px, #000 70px 60px,
        #000 80px 45px, #000 110px 95px, #000 90px 85px;
    }
    75% {
      box-shadow: #000 30px 95px, #000 40px 100px, #000 50px 60px, rgba(0, 0, 0, 0) 55px 95px, #000 70px 80px,
        #000 80px 70px, rgba(0, 0, 0, 0) 110px 120px, rgba(0, 0, 0, 0) 90px 110px;
    }
    76% {
      box-shadow: #000 30px 95px, #000 40px 100px, #000 50px 60px, rgba(0, 0, 0, 0) 55px 35px, #000 70px 80px,
        #000 80px 70px, rgba(0, 0, 0, 0) 110px 25px, rgba(0, 0, 0, 0) 90px 15px;
    }
    100% {
      box-shadow: rgba(0, 0, 0, 0) 30px 120px, rgba(0, 0, 0, 0) 40px 120px, #000 50px 75px, #000 55px 50px,
        #000 70px 100px, #000 80px 95px, #000 110px 45px, #000 90px 35px;
    }
  }
</style>
