/**
 * MsalConfig.ts
 */
import type * as Msal from '@azure/msal-browser';

/**
 * Configuration parameters for MSAL instance
 */
export const msalConfig = {
  auth: {
    clientId: global.APP_CLIENT_ID as string,
    authority: global.APP_LOGIN_LINK as string,
    postLogoutRedirectUri: global.APP_REDIRECT_URI as string,
    redirectUri: global.APP_REDIRECT_URI as string,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

/**
 * Login request scopes for initializing loginPopup function
 */
export const loginRequest = {
  scopes: ['user.read'],
};

export function logoutRequest(msalServiceReq: Msal.PublicClientApplication) {
  return {
    account: msalServiceReq.getActiveAccount(),
    mainWindowRedirectUri: global.APP_REDIRECT_URI as string,
  };
}

export const accessRefreshTokenRequest = (account: Msal.AccountInfo) => {
  return {
    scopes: ['user.read'],
    account: account,
  };
};
