<script lang="ts">
  import { Router, Route, navigate } from 'svelte-navigator';
  // Components
  import Header from './modules/header/presentation/page/Header.svelte';
  import Dashboard from './modules/dashboard/presentation/page/Dashboard.svelte';
  import Need from './modules/need/presentation/page/Need.svelte';
  import Authentication from './modules/authentication/presentation/page/Authentication.svelte';
  import ErrorPage from './modules/error/presentation/page/ErrorPage.svelte';
  import Candidate from './modules/candidate/presentation/page/Candidate.svelte';
  import { DefaultError } from './constants/Errors';
</script>

<Router primary={false}>
  <main>
    <!-- login -->
    <Route path="/auth">
      <Authentication />
    </Route>

    <!-- dashboard -->
    <Route path="/">
      {#if sessionStorage.length > 1}
        <Header showBar />
        <Dashboard />
      {:else}
        {navigate('/auth')}
      {/if}
    </Route>

    <!-- need project page -->
    <Route path="/need/:id" let:params>
      {#if sessionStorage.length > 1}
        <Header />
        <Need needId={params.id} />
      {:else}
        {navigate('/auth')}
      {/if}
    </Route>

    <!-- candidate detail page -->
    <Route path="/candidate/:applicationId" let:params let:location>
      {#if sessionStorage.length > 1}
        <Header />
        <Candidate applicationId={params.applicationId} needType={location.state.needType} />
      {:else}
        {navigate('/auth')}
      {/if}
    </Route>

    <!-- Error route -->
    <Route path="/err" let:location>
      {#if sessionStorage.length > 1}
        <Header />
        <ErrorPage error={location.state.error} />
      {:else}
        {navigate('/auth')}
      {/if}
    </Route>

    <!-- Default route -->
    <Route>
      {#if sessionStorage.length > 1}
        <Header />
        <ErrorPage error={DefaultError} />
      {:else}
        {navigate('/auth')}
      {/if}
    </Route>
  </main>
</Router>

<style lang="scss">
  main {
    position: relative;
    overflow: hidden;
    color: #303030;
  }
</style>
