/**
 * MsalController.ts
 *
 * Desc: controller of user from msal, microsoft handles token and popup for login
 */

import {MsalService} from "../domain/services/MsalService";
import type {MsalUser} from "../domain/models/types/MsalUser";

/**
 * This service do not need anymore a msalRepository because we had a circular dependency
 * the link between controller and repository used to be here with an import of the repository
 *
 * (!) Circular dependency
 * src/modules/authentication/application/MsalController.ts
 * -> src/modules/authentication/infrastructure/MsalRepository.ts // The link we broke
 * -> src/modules/authentication/infrastructure/api/UserRepository.ts
 * -> src/helpers/ApiRepository.ts
 * -> src/modules/authentication/application/MsalController.ts
 *
 */
const msalService = new MsalService()


/**
 * function to getUser from microsoft service and load it into session storage
 */
export async function getMsalUser(): Promise<MsalUser> {
    let msalUserData = sessionStorage.getItem('msalUser')
    let msalUser: MsalUser

    if(!msalUserData) {
        msalUser = await msalService.getUser();
        const name = msalUser.account.name
        const userName = msalUser.account.username

        sessionStorage.setItem("msalUser", JSON.stringify({account: { name: name, username: userName }}))

        return msalUser
    } else {
        return JSON.parse(msalUserData)
    }
}

/**
 * function to get the refresh token
 */
export async function getIdToken() {
    return msalService.getIdToken();
}

/**
 * function to logout properly from the app
 */
export function logout() {
    sessionStorage.clear();
    return msalService.logout();
}