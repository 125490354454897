<script lang="ts">
  import {
    displayDate,
    displayNote,
    displayModifiedBy,
    displayAvailability,
    displayRefusedReason
  } from '../../application/CandidateDisplayHelper';
  // Types
  import type { RefusedReason } from '../../../../models/RefusedReason';
  import type { StepExtended } from '../../domain/types/Step';
  // Imported variables
  export let step: StepExtended;
  export let reason: RefusedReason;
</script>

<li>
  <div class="info">
    <time>🗓 {displayDate(new Date(step.date))}</time>
    <span>{displayModifiedBy(step.modifiedBy)}</span>
  </div>
  <div class="marker"></div>
  <div class="content">
    <h3>{step.name}</h3>
    {#if step.name === 'Refused'}
      <p>{displayRefusedReason(reason)}</p>
    {/if}
    <p>{displayAvailability(step.availability)}</p>
    <p>
      <em>{displayNote(step.note)}</em>
    </p>
  </div>
</li>

<style lang="scss">
  li {
    flex: 1;
    padding-left: 4rem;
    position: relative;

    &:hover .marker:before {
      background: transparent;
      border: 3px solid #37f489;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    overflow-x: auto;
    overflow-y: hidden;
  }
  p {
    display: flex;
  }

  .marker {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 1.4rem;
    padding: 1.4rem;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 4px;
      left: 50%;
      transform: translate(-50%);
      width: 1.5rem;
      height: 1.5rem;
      background: #37f489;
      border: 3px solid transparent;
      border-radius: 50%;
      transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
    }
    &:after {
      content: '';
      position: absolute;
      top: 2.4rem;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
      width: 3px;
      display: block;
      background: #ccd5db;
    }
  }
</style>
