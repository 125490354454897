<script lang="ts">
  import { createEventDispatcher, onMount } from 'svelte';
  import { flip } from 'svelte/animate';
  // Components
  import CareerCard from './CareerCard.svelte';
  // Types
  import type { Application } from '../../../../models/Application';
  import type { ApplicationBoard } from '../../domain/models/types/ApplicationBoard';
  // Application
  import {
    allowModalToAppear,
    calculateAllowedOperation,
    getApplicationBoard,
  } from '../../application/ApplicationController';
  import { currentNeedStore } from '../../infrastracture/store/currentNeedStore';

  const dispatch = createEventDispatcher();
  let promiseApplicationBoard;
  let needType: string;

  onMount(() => {
    needType = $currentNeedStore.type;
  });

  $: {
    promiseApplicationBoard = getApplicationBoard($currentNeedStore);
  }

  function handleDragStart(event, application: Application, draggedBoard: ApplicationBoard) {
    // The data we want to make available when the element is dropped
    // when the candidate being dragged
    event.dataTransfer.setData('candidate_moved', JSON.stringify({ application, draggedBoard }));
  }

  async function handleDrop(event, droppedBoard: ApplicationBoard) {
    const json = event.dataTransfer.getData('candidate_moved');
    const { application, draggedBoard } = JSON.parse(json);

    // Allowed operation
    const allow = await calculateAllowedOperation(draggedBoard, droppedBoard);

    if (allow) {
      // Send application data through event
      dispatch('candidateMoved', { data: { application, droppedBoard } });

      // function to dispatch event to show modal
      allowModalToAppear(dispatch, droppedBoard);
    }
  }
</script>

{#await promiseApplicationBoard then applicationBoard}
  <section>
    {#each applicationBoard as board (board)}
      <article animate:flip>
        <header>{board.name} ({board.candidates.length})</header>
        <ul on:drop|preventDefault={(event) => handleDrop(event, board)} on:dragover|preventDefault={() => false}>
          {#each board.candidates as candidate (candidate)}
            <div animate:flip>
              <CareerCard
                {needType}
                isInactive={candidate.state === 'Inactive'}
                isResigned={candidate.status === 'Resigned'}
                career={candidate.career}
                applicationId={candidate.id}
                on:dragstart={(event) => handleDragStart(event, candidate, board)}
              />
            </div>
          {/each}
        </ul>
      </article>
    {/each}
  </section>
{/await}

<style lang="scss">
  section {
    padding: 1rem;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(4, minmax(34rem, 1fr));
    overflow-x: auto;
    overflow-y: hidden;
  }

  article {
    height: calc(100vh - 20.5rem);
    padding-bottom: 1rem;
    color: inherit;
    background: #f8f8f8;
    border-radius: 0.5rem;
    overflow: hidden;
    transition: all 0.2s;

    &:hover {
      box-shadow: 0 1px 3px rgba(#676767, 0.8);
    }
  }

  header {
    width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    font-size: 1.8rem;
    font-weight: bold;
    border-bottom: 1px solid rgba(#676767, 0.1);
  }

  ul {
    flex: 1 1 auto;
    height: 87%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 1rem 1rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
</style>
