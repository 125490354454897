import {get} from 'svelte/store';
import {needListStore} from '../../dashboard/infrastructure/store/NeedListStore';
import {filteredNeedStore} from '../infrastructure/filteredNeedStore';

export const handleSearchChanged = (term) => {
    if (term) {
        filteredNeedStore.update((currentStore) => {
            return currentStore.filter((need) => need.name.toLowerCase().includes(term.toLowerCase()));
        });
    } else {
        filteredNeedStore.set(get(needListStore));
    }
};
