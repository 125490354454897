import { ApiRepository } from '../../../../helpers/ApiRepository';
import type { NeedListInterface } from '../../domain/models/interfaces/NeedListInterface';

export class NeedListRepository implements NeedListInterface {
  /**
   *  Function for getting Need list from API
   */
  async get(companyId: string) {
    try {
      const { data } = await ApiRepository.get(`/needs?companyId=${companyId}`);
      return data;
    } catch (err) {
      throw err;
    }
  }
}

export const dashboardRepository = new NeedListRepository();
