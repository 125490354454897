<script lang="ts">
  // Components
  import FaUserCircle from 'svelte-icons/fa/FaUserCircle.svelte';
  import TiArrowBack from 'svelte-icons/ti/TiArrowBack.svelte';
  import Wrapper from '../../../../lib/Wrapper.svelte';
  import Spinner from '../../../../lib/Spinner.svelte';
  import Stage from '../components/Stage.svelte';
  // Application
  import { getApplication } from '../../application/CandidateController';
  // Helpers
  import { displaySalary } from '../../application/CandidateDisplayHelper';
  // Imported variables
  export let applicationId: string;
  export let needType: string;
</script>

<Wrapper>
  {#await getApplication(applicationId)}
    <Spinner />
  {:then application}
    <section>
      <button on:click|preventDefault={() => history.back()}>
        <TiArrowBack />
      </button>
      <aside>
        <div class="person">
          <h4>{application.career.name}</h4>
          <picture class="logo">
            <FaUserCircle />
          </picture>
          <h4>{application.career.trigram}</h4>
        </div>

        <div class="info">
          <p>📂 Type of contract : {application.career.typeOfContract}</p>
          {#if needType !== 'Outsourcing'}
            <p>
              {displaySalary(
                application.career.typeOfContract,
                application.career.expectedSalary,
                application.career.dayRate
              )}
            </p>
          {/if}
        </div>
        <!-- <div class="files">
          <h4>Attached files:</h4>
          <div class="icons">
            <picture class="icon">
              <FaRegFilePdf />
            </picture>
            <picture class="icon">
              <FaRegFileCode />
            </picture>
          </div>
        </div> -->
      </aside>
      <main>
        <h4>Activity</h4>
        <ul>
          {#each application.stageHistory as step}
            <Stage {step} reason={application.refusedReason} />
          {/each}
        </ul>
      </main>
    </section>
  {/await}
</Wrapper>

<style lang="scss">
  section {
    position: relative;
    height: 100%;
    display: flex;
    gap: 2rem;
    margin: 2rem;
    padding: 2rem;
    background: #f8f8f8;
    border-radius: 1rem;
    transition: all 0.2s;
    border: 3px solid transparent;

    @media only screen and (max-width: 56.25em) {
      flex-direction: column;
    }
  }

  aside {
    margin-top: 2rem;
    flex: 0 0 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    border-right: 1px solid rgba(#676767, 0.1);

    @media only screen and (max-width: 56.25em) {
      border: none;
    }
  }

  main {
    margin-top: 2rem;
    height: calc(100vh - 18rem);
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0 4rem;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1.6rem;
    overflow-x: auto;
    overflow-y: scroll;
  }

  div {
    display: flex;
    flex-direction: column;
  }

  .person {
    gap: 1.4rem;
    align-items: center;
  }

  .logo {
    height: 9rem;
    width: 9rem;
  }

  h4 {
    font-size: 2rem;
  }

  .info {
    margin: 0 auto;
    align-self: start;
    gap: 0.4rem;
  }

  p {
    font-size: 1.6rem;
  }

  picture {
    height: 5rem;
    width: 5rem;
  }

  button {
    position: absolute;
    top: 2rem;
    left: 2rem;
    cursor: pointer;
    outline: none;
    border: none;
    color: inherit;
    background: inherit;
    height: 3.5rem;
    width: 3.5rem;

    &:hover,
    &:focus {
      color: #2ff780;
      outline: none;
      animation: hover 1200ms linear 2 alternate;
    }

    &:active {
      animation: active 1200ms ease 1 alternate;
    }
  }

  @keyframes active {
    0% {
      transform: scale(1, 1);
    }
    90% {
      transform: scale(0.9, 0.88);
    }
    100% {
      transform: scale(0.92, 0.9);
    }
  }
  @keyframes hover {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    2% {
      -webkit-transform: matrix3d(1.016, 0, 0, 0, 0, 1.037, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.016, 0, 0, 0, 0, 1.037, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    3% {
      -webkit-transform: matrix3d(1.033, 0, 0, 0, 0, 1.094, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.033, 0, 0, 0, 0, 1.094, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    4% {
      -webkit-transform: matrix3d(1.045, 0, 0, 0, 0, 1.129, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.045, 0, 0, 0, 0, 1.129, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    5% {
      -webkit-transform: matrix3d(1.051, 0, 0, 0, 0, 1.142, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.051, 0, 0, 0, 0, 1.142, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    7% {
      -webkit-transform: matrix3d(1.068, 0, 0, 0, 0, 1.158, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.068, 0, 0, 0, 0, 1.158, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    8% {
      -webkit-transform: matrix3d(1.084, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.084, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    9% {
      -webkit-transform: matrix3d(1.088, 0, 0, 0, 0, 1.132, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.088, 0, 0, 0, 0, 1.132, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    10% {
      -webkit-transform: matrix3d(1.097, 0, 0, 0, 0, 1.107, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.097, 0, 0, 0, 0, 1.107, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    12% {
      -webkit-transform: matrix3d(1.108, 0, 0, 0, 0, 1.077, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.108, 0, 0, 0, 0, 1.077, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    14% {
      -webkit-transform: matrix3d(1.114, 0, 0, 0, 0, 1.067, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.114, 0, 0, 0, 0, 1.067, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    15% {
      -webkit-transform: matrix3d(1.115, 0, 0, 0, 0, 1.067, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.115, 0, 0, 0, 0, 1.067, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    16% {
      -webkit-transform: matrix3d(1.119, 0, 0, 0, 0, 1.077, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.119, 0, 0, 0, 0, 1.077, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    17% {
      -webkit-transform: matrix3d(1.121, 0, 0, 0, 0, 1.096, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.121, 0, 0, 0, 0, 1.096, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    18% {
      -webkit-transform: matrix3d(1.121, 0, 0, 0, 0, 1.102, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.121, 0, 0, 0, 0, 1.102, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    20% {
      -webkit-transform: matrix3d(1.121, 0, 0, 0, 0, 1.113, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.121, 0, 0, 0, 0, 1.113, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    22% {
      -webkit-transform: matrix3d(1.119, 0, 0, 0, 0, 1.119, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.119, 0, 0, 0, 0, 1.119, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    24% {
      -webkit-transform: matrix3d(1.115, 0, 0, 0, 0, 1.11, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.115, 0, 0, 0, 0, 1.11, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    25% {
      -webkit-transform: matrix3d(1.113, 0, 0, 0, 0, 1.102, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.113, 0, 0, 0, 0, 1.102, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    29% {
      -webkit-transform: matrix3d(1.106, 0, 0, 0, 0, 1.089, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.106, 0, 0, 0, 0, 1.089, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    29% {
      -webkit-transform: matrix3d(1.105, 0, 0, 0, 0, 1.09, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.105, 0, 0, 0, 0, 1.09, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    35% {
      -webkit-transform: matrix3d(1.098, 0, 0, 0, 0, 1.105, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.098, 0, 0, 0, 0, 1.105, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    36% {
      -webkit-transform: matrix3d(1.097, 0, 0, 0, 0, 1.106, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.097, 0, 0, 0, 0, 1.106, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    41% {
      -webkit-transform: matrix3d(1.096, 0, 0, 0, 0, 1.099, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.096, 0, 0, 0, 0, 1.099, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    44% {
      -webkit-transform: matrix3d(1.096, 0, 0, 0, 0, 1.097, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.096, 0, 0, 0, 0, 1.097, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    51% {
      -webkit-transform: matrix3d(1.099, 0, 0, 0, 0, 1.102, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.099, 0, 0, 0, 0, 1.102, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    52% {
      -webkit-transform: matrix3d(1.099, 0, 0, 0, 0, 1.102, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.099, 0, 0, 0, 0, 1.102, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    58% {
      -webkit-transform: matrix3d(1.101, 0, 0, 0, 0, 1.099, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.101, 0, 0, 0, 0, 1.099, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    63% {
      -webkit-transform: matrix3d(1.101, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.101, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    66% {
      -webkit-transform: matrix3d(1.101, 0, 0, 0, 0, 1.101, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.101, 0, 0, 0, 0, 1.101, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    73% {
      -webkit-transform: matrix3d(1.1, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.1, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    81% {
      -webkit-transform: matrix3d(1.1, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.1, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    85% {
      -webkit-transform: matrix3d(1.1, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.1, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    88% {
      -webkit-transform: matrix3d(1.1, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.1, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    96% {
      -webkit-transform: matrix3d(1.1, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.1, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    100% {
      -webkit-transform: matrix3d(1.1, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.1, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
  }

  // .files {
  //   display: flex;
  //   gap: 1rem;
  // }

  // .icons {
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: space-around;
  // }

  // .icon {
  //   cursor: pointer;
  //   height: 4.5rem;
  //   width: 4.5rem;
  //   transition: all 0.2s;

  //   &:hover {
  //     color: #37f489;
  //     animation: jelly 0.5s 1;
  //   }
  // }

  // @keyframes jelly {
  //   from,
  //   to {
  //     -webkit-transform: scale(1, 1);
  //     transform: scale(1, 1);
  //   }
  //   25% {
  //     -webkit-transform: scale(0.95, 1.01);
  //     transform: scale(0.95, 1.01);
  //   }
  //   50% {
  //     -webkit-transform: scale(1.01, 0.95);
  //     transform: scale(1.01, 0.95);
  //   }
  //   75% {
  //     -webkit-transform: scale(0.99, 1.09);
  //     transform: scale(0.99, 1.01);
  //   }
  // }
</style>
