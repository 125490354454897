import type {ApplicationInterface} from '../../domain/models/interfaces/ApplicationInterface';

import {ApiRepository} from '../../../../helpers/ApiRepository';
import type {Stage} from "../../domain/models/types/Stage";
import type {ApplicationUpdate as Application} from "../../domain/models/types/Application";
import {getMsalUser} from "../../../authentication/application/MsalController";
import {getUserInfo} from "../../../authentication/application/UserController";

class ApplicationRepository implements ApplicationInterface {
    async update(
        applicationId: string,
        application: Application
    ): Promise<Application> {
        const msalUser = await getMsalUser()
        const user = await getUserInfo(msalUser.account.username)

        try {
            const {data} = await ApiRepository.post(
                `/application/${applicationId}`,
                {
                    status: application.status,
                    activeStage: application.activeStage,
                    note: application.note,
                    availability: application.availability,
                    refusedReasonCode: application.refusedReasonCode,
                    modifiedById: user.contactId
                }
            );
            return data;
        } catch (err) {
            throw err;
        }
    }


    async getStages(): Promise<Stage[]> {
        try {
            const {data} = await ApiRepository.get(
                `/stages`
            );
            return data;
        } catch (err) {
            throw err;
        }
    }
}

export const applicationRepository = new ApplicationRepository();
