/**
 * NeedListController.ts
 *
 * Authors: Guilhem BERTHALON, Nicolas Galois, Dmytro Teliakovskyi
 */

// Store
import { get } from 'svelte/store';
import { needListStore } from '../infrastructure/store/NeedListStore';
// Domain
import { NeedListService } from '../domain/services/NeedListService';
// Infrastructure
import { dashboardRepository } from '../infrastructure/api/NeedListRepository';
import { filteredNeedStore } from '../../header/infrastructure/filteredNeedStore';
import {getUserInfo} from "../../authentication/application/UserController";
import {getMsalUser} from "../../authentication/application/MsalController";
// Application

const needListService = new NeedListService(dashboardRepository);

/**
 * Function for setting need list in the store
 * if error set need list error because company not found and navigate to /err
 */
export const loadNeedList = async () => {
  // Get companyId from sessionStorage
  const msalUser = await getMsalUser()

  const user = await getUserInfo(msalUser.account.username)
  const companyId = user.companyId

  const needList = get(needListStore);
  if (needList.length === 0) {
    // If need list store not set
    // request API
    const needList = await needListService.get(companyId);
    // Set to store
    needListStore.set(needList);
    filteredNeedStore.set(needList);
  }
};
/**
 * Function for getting need list in the store
 * if error set need list error because company not found and navigate to /err
 * @param needId
 */
export const getNeed = (needId: string) => {
  return get(needListStore).find((need) => need.id === needId);
};

/**
 * Function for calculating new candidature count status and display it
 * Only used when candidate status is updated
 */
export const updateNeedListCount = (
  draggedBoard: string,
  droppedBoard: string,
  needId: string,
  updatedDate: string
) => {
  // We need to calculate count candidates for dragged and dropped board for the needId

  // Get the need from ours needList
  const need = getNeed(needId);

  if (need) {
    // update last updated date
    need.lastUpdate = updatedDate;

    need.statuses.forEach((status) => {
      if (status.name === draggedBoard) {
        // this status count lost a candidate
        status.count -= 1;
      }
      if (status.name === droppedBoard) {
        // this status count gain a candidate
        status.count += 1;
      }
    });

    // update need list store
    needListStore.update((currentNeedList) => {
      return currentNeedList.map((currentNeed) => {
        if (currentNeed.id === needId) {
          return need; // need updated
        } else {
          return currentNeed; // other needs
        }
      });
    });
  }
};
