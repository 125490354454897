import type { NeedInterface } from '../../domain/models/interfaces/NeedInterface';
import type { NeedExtended as Need } from '../../domain/models/types/Need';

import { ApiRepository } from '../../../../helpers/ApiRepository';

class NeedRepository implements NeedInterface {
  /**
   *  Function for getting Need from API
   */
  async get(needId: string): Promise<Need> {
    try {
      const { data } = await ApiRepository.get(`/need/${needId}`);
      return data;
    } catch (err) {
      throw err;
    }
  }
}

export const needRepository = new NeedRepository();
