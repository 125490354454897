import type { NeedInterface } from '../models/interfaces/NeedInterface';
import type { NeedExtended as Need } from '../models/types/Need';

export class NeedService {
  constructor(protected needRepository: NeedInterface) {}

  // Function to receive Need data from Need Repository
  public async get(needId: string): Promise<Need> {
    return this.needRepository.get(needId);
  }
}
