/**
 * candidateTore.ts
 *
 * Desc: store for concrete model Application
 */

import { writable } from 'svelte/store';
import type { ApplicationExtended as Application } from '../../domain/types/Application';

export const candidateStore = writable([] as Application[]);
