import type {Error} from "../domain/models/types/Error";
import {getMsalUser} from "../../authentication/application/MsalController";
import {getUserInfo} from "../../authentication/application/UserController";

export const displayStatus = (error: Error) => {
    return error.response.data.status ? displayErrorStatus(error.response.data.status) : error.response.status
}

export const displayMessage = async(error: any) => {
    return error.response.data.message ? await displayErrorMessage(error.response.data.message) : error.response.statusText
}

/**
 * function to replace the contact mailbox with the one we get from /auth request which is depending on our country location
 * for custom error
 * @param msg
 */
const displayErrorMessage = async(msg: string) => {
    return (
        msg
            ? msg.replace("${contactMailBox}", await displayMailBox())
            : ""
    )
}

const displayMailBox = async() => {
    const msalUser = await getMsalUser()
    const user = await getUserInfo(msalUser.account.username)
    return user.contactMailBox
}

/**
 * function to display status from custom error
 * @param status
 */
const displayErrorStatus = (status: string) => {
    return status ? status : ""
}