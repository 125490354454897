/**
 * ApplicationController.ts
 *
 * Authors: Guilhem BERTHALON, Nicolas Galois, Dmytro Teliakovskyi
 */
// Types
import type {NeedExtended as Need} from '../domain/models/types/Need';
import type {ApplicationBoard} from '../domain/models/types/ApplicationBoard';
import type {Application} from '../../../models/Application';
import type {Stage} from '../domain/models/types/Stage';
// Domain
import {ApplicationService} from '../domain/services/ApplicationService';
// Application
import {applicationRepository} from '../infrastracture/api/ApplicationRepository';
import {getStages} from "./StagesController";

const applicationService = new ApplicationService(applicationRepository);

/**
 * Function to update application on CRM
 * @param application
 * @param note
 * @param availability
 * @param refusedReasonCode
 */
export const updateApplication = async (application: Application, note?: string, availability?: string, refusedReasonCode?: string) => {
    return applicationService.update(
        application.id,
        {
            status: application.status,
            activeStage: application.activeStage,
            note: note,
            availability: availability,
            refusedReasonCode: refusedReasonCode
        }
    );
};

/**
 * Function to sort candidates between boards
 * @param need of which we get the candidate from
 */
export const getApplicationBoard = async(need: Need): Promise<ApplicationBoard[]> => {
    // initialization of board candidates
    // Get board names from stage controller
    const boards = await getStages()

    return boards.map((board) => {
        return {
            name: board.name,
            candidates: filterCandidatesByBoardName(need.applications, board.name)
        }
    })
}

/**
 * Function to filter application by activeStage
 * @param applications
 * @param boardName
 * @returns an array of application where boardName and activeStage are equal
 */
const filterCandidatesByBoardName = (applications: Application[], boardName: string): Application[] => {
    return applications.filter((application) => {
        return application.activeStage === boardName;
    });
};

/**
 * Function to calculate allowed operation for the application board
 * @param draggedBoard
 * @param droppedBoard
 */
export const calculateAllowedOperation = async(draggedBoard: ApplicationBoard, droppedBoard: ApplicationBoard) => {
    // Allow operation to be made
    // if you go to a board your board name must be in allowedOrigin
    const stages = await getStages();

    // GET the stage the candidate moved to
    const destinationStage: Stage = stages.filter((board) => {
        return board.name === droppedBoard.name;
    })[0];

    return (
        // Does the destination stage allow your origin to come in ?
        destinationStage.allowedOrigin.includes(draggedBoard.name)
    );
};

/**
 * Function to emit events to show the modal we want to or any
 * @param droppedBoard
 * @param dispatch
 */
export const allowModalToAppear = (dispatch, droppedBoard: ApplicationBoard) => {
    const {uncaughtByModalAllowedOperation, isInterviewed, isAccepted, isRefused} = whereCandidateMovedTo(droppedBoard);

    // Accepted modal
    dispatch('showModalAccepted', {data: isAccepted});
    // Interviewed modal
    dispatch('showModalInterviewed', {data: isInterviewed});
    // Refused modal
    dispatch('showModalRefused', {data: isRefused});

    // no modal
    if (uncaughtByModalAllowedOperation) dispatch('showNoModal');
};

/**
 * private function to know where the candidate moved into
 * @param droppedBoard
 */
const whereCandidateMovedTo = (droppedBoard: ApplicationBoard) => {
    return {
        uncaughtByModalAllowedOperation: droppedBoard.name === 'Applied', // No modal
        isInterviewed: droppedBoard.name === 'Interviewing', // to interviewing
        isAccepted: droppedBoard.name === 'Accepted', // to accepted
        isRefused: droppedBoard.name === 'Refused', // to refused
    };
};
