/**
 * Errors.ts
 *
 * Desc: error constants from the front
 */

import type { Error } from '../modules/error/domain/models/types/Error';

export const DefaultError: Error = {
  response: {
    data: {
      status: '404 Not Found',
      message:
        "Make sure the address is correct and the page hasn't moved. Please contact your Bertek administrator if you think this is a mistake.",
    },
  },
};

export const CVError: Error = {
  response: {
    data: {
      status: 'Sorry, we could not  find the CV',
      message: 'There is no CV associated to this candidate. You can ask your Bertek contact to get the CV',
      noFound: true,
    },
  },
};
