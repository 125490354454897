<script lang="ts">
  // Types
  import type { NeedExtended as Need } from '../../domain/models/types/Need';
  import { currentNeedStore } from '../../infrastracture/store/currentNeedStore';
  // Exported variables
  //export let need: Need;
  let need: Need = $currentNeedStore;
  let active: boolean = false;

  function expand() {
    active = !active;
  }
</script>

<header>
  <div class={need.type.toLocaleLowerCase()}><span class="type">{need.type}</span></div>
  <main>
    <div class="title">
      <span class="label">Title:</span>
      <span>{need.title}</span>
    </div>
    <div class="skills">
      {#each need.skills as skill}
        <span class="icon"> {skill} </span>
      {/each}
    </div>
  </main>
  <aside>
    <div class="description">
      <span class="label">Description:</span>
      <span>{need.description.slice(0, 165)}</span>
      {#if need.description.slice(0, 165).length < 165}
        <span />
      {:else if !active}
        <span class="expand" on:click={expand}> see more...</span>
      {:else}
        <span>{need.description.slice(165)}</span>
        <span class="expand" on:click={expand}> ...see less</span>
      {/if}
    </div>
  </aside>
</header>

<style lang="scss">
  header {
    position: relative;
    display: flex;
    gap: 2rem;
    padding: 2rem;
    font-size: 1.6rem;
    color: inherit;
    background: #f8f8f8;
    border-radius: 0.5rem;
    margin: 1rem 1rem 0 1rem;
    overflow: hidden;
    transition: all 0.2s;

    @media only screen and (max-width: 36em) {
      flex-wrap: wrap;
      gap: 0.5rem;
    }

    &:hover {
      box-shadow: 0 1px 3px rgba(#676767, 0.6);
    }
  }

  main {
    min-width: 32rem;
    flex: 0 0 35%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border-right: 1px solid rgba(#676767, 0.1);

    @media only screen and (max-width: 36em) {
      flex: 1;
      border-right: 1px solid transparent;
    }
  }
  aside {
    flex: 1;
  }

  .description {
    padding-right: 2rem;
  }

  .subscription,
  .permanent,
  .outsourcing {
    height: 7rem;
    width: 7rem;
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    clip-path: polygon(100% 0, 0 0, 100% 100%);
  }

  .subscription {
    background: #37f487;
  }

  .permanent {
    background: #3fb7f1;
  }

  .outsourcing {
    background: #ffa339;
  }

  .type {
    position: absolute;
    top: 2.1rem;
    right: -0.6rem;
    display: flex;
    color: #fdfefe;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1rem;
    transform: rotate(45deg);
  }

  span {
    font-size: 1.6rem;
  }

  .label {
    font-size: 1.8rem;
    font-weight: bold;
  }

  .skills {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .icon {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: bold;
    font-size: 1rem;
    color: #fdfefe;
    background: #ff400e;
    border-radius: 2rem;
  }

  .expand {
    cursor: pointer;
    font-weight: normal;
    font-size: 1.4rem;

    &:hover {
      text-decoration: underline;
    }
  }
</style>
