<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  // Components
  import Modal from '../../../../../lib/Modal.svelte';
  import Button from '../../../../../lib/Button.svelte';
  import FaQuestionCircle from 'svelte-icons/fa/FaQuestionCircle.svelte';
  // Imported variables
  export let handleRequest: Function;
  export let interview: boolean = false;

  let note: string;
  let availability: string;

  const dispatch = createEventDispatcher();

  const submit = () => {
    dispatch('closeModal', { data: false });
    handleRequest(note, availability);
  };
</script>

<Modal isOpen={interview}>
  <form id="interviewModal">
    <main>
      <aside>
        <i>
          <FaQuestionCircle />
        </i>
      </aside>
      <aside>
        <header>
          <h2>Candidate is about to have an interview</h2>
          <h4>To confirm it, please fill information below</h4>
        </header>
        <section>
          <div>
            <label for="availability">Availability</label>
            <input id="availability" type="text" bind:value={availability} />
          </div>
          <div>
            <label for="notes">Notes</label>
            <textarea form="interviewModal" id="notes" bind:value={note} rows="4" cols="42" />
          </div>
        </section>
      </aside>
    </main>

    <footer>
      <Button
        type={'cancel'}
        on:click={() => {
          dispatch('closeModal', { data: false });
        }}>Cancel</Button
      >
      <Button on:click={submit}>Send</Button>
    </footer>
  </form>
</Modal>

<style lang="scss">
  form {
    display: flex;
    flex-direction: column;
  }

  main {
    display: flex;
    gap: 2rem;
    padding: 2rem 4rem;

    @media only screen and (max-width: 36em) {
      gap: 1rem;
    }
  }

  aside {
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  i {
    margin-top: 1rem;
    display: block;
    width: 4rem;
    height: 4rem;
  }

  header {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
  }

  h2 {
    font-size: 2.2rem;
  }

  h4 {
    font-size: 1.8rem;
    font-weight: 300;
  }

  section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;
  }

  div {
    display: flex;
    align-items: flex-start;
  }

  label {
    flex: 0 0 22%;
    font-size: 1.6rem;
  }

  input {
    font-family: inherit;
    color: inherit;
    display: inline-block;
    padding: 0.5rem;
    flex: 0 0 80%;
    outline: none;
    border: 1px solid #696060;
    border-radius: 3px;
    font-size: 1.4rem;
    background-color: inherit;

    &:focus {
      outline: none;
      border: 1px solid #37f489;
    }
  }

  textarea {
    font-family: inherit;
    color: inherit;
    display: inline-block;
    padding: 0.5rem;
    height: 8rem;
    width: 80%;
    flex: 0 0 80%;
    resize: vertical;
    border-radius: 3px;
    font-size: 1.4rem;

    &:focus {
      outline: none;
      border: 1px solid #37f489;
    }
  }

  footer {
    padding: 2rem 4rem;
    background: #f8f8f8;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    border-top: 1px solid #e8e8e8;
  }
</style>
