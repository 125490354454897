/**
 * NeedListController.ts
 *
 * Desc: Controller of need list
 *
 * Author: Guilhem BERTHALON, Nicolas Galois, Dmytro Teliakovskyi
 *
 */
import {get} from 'svelte/store';
// Types
import type {ApplicationExtended as Application} from '../domain/types/Application';
import type {StepExtended as Step} from '../domain/types/Step';
// Domain
import {CandidateService} from '../domain/services/CandidateService';
// Infrastructure
import {candidateRepository} from '../infrastructure/api/CandidateRepository';
import {candidateStore} from '../infrastructure/store/candidateStore';
import type {RefusedReason} from "../../../models/RefusedReason";
import {navigate} from "svelte-navigator";
import {DefaultError} from "../../../constants/Errors";
import type {Error} from "../../error/domain/models/types/Error";
// Application

const candidateService = new CandidateService(candidateRepository);

/**
 * Function to get candidates and set it in the store
 * @param applicationId
 */
export const getApplication = async (applicationId: string): Promise<Application> => {
    let candidate = getApplicationInStore(applicationId);

    if (candidate) {
        return candidate;
    } else {
        // request API
        try {
            candidate = await candidateService.get(applicationId);
        } catch (e) {
            // if we send only {state: { error: e }} we loose e.response.data which are our custom errors from the back
            const error: Error = (
                e.response.data.status? e.response.data: DefaultError.response.data
            )
            navigate('/err', {state: {error: {response: {data: error}}}})
            throw e
        }

        // Set to the store
        candidateStore.update((currentCandidateStore) => {
            return [...currentCandidateStore, candidate as Application];
        });

        return candidate;
    }
};

/**
 * private Function to get one application in the store, can be empty because we do not want to load every candidate we do move but never look into detail
 * @param applicationId
 */
const getApplicationInStore = (applicationId: string): Application | undefined => {
    return get(candidateStore).find((currentCandidate) => currentCandidate.id === applicationId);
};

/**
 * Function for adding the new state to the application to the store
 * @param applicationId
 * @param newStage
 */
export const pushToHistoryOfCandidate = (applicationId: string, newStage: Step) => {
    let application = getApplicationInStore(applicationId);
    if (application) {
        // Only if in store
        application.stageHistory.unshift(newStage);
    }
};

/**
 * Function for popping one stage of history in the one candidate to the store
 * @param applicationId
 */
export const popToHistoryOfCandidate = (applicationId: string) => {
    let application = getApplicationInStore(applicationId);
    if (application) {
        // Only if in store
        application.stageHistory.shift();
    }
};

/**
 * function to update the refused reason to the application
 * @param applicationId
 * @param refusedReason
 */
export const updateRefusedReason = (applicationId: string, refusedReason: RefusedReason) => {
    let application = getApplicationInStore(applicationId);
    if (application) {
        // Only if in store
        application['refusedReason'] = refusedReason
    }
}
