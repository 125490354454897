<script context="module">
  // for passing focus on to the next Modal in the queue.
  // A module context level object is shared among all its component instances. [Read More Here](https://svelte.dev/tutorial/sharing-code)
  const modalList = [];
</script>

<script>
  import { scale } from 'svelte/transition';
  export let isOpen = false;

  function transitionend(e) {
    const node = e.target;
    node.focus();
  }

  function modalAction(node) {
    const returnFn = [];
    // for accessibility
    if (document.body.style.overflow !== 'hidden') {
      const original = document.body.style.overflow;
      document.body.style.overflow = 'hidden';
      returnFn.push(() => {
        document.body.style.overflow = original;
      });
    }

    node.addEventListener('transitionend', transitionend);
    node.focus();
    modalList.push(node);
    returnFn.push(() => {
      node.removeEventListener('transitionend', transitionend);
      modalList.pop();
      // Optional chaining to guard against empty array.
      modalList[modalList.length - 1]?.focus();
    });
    return {
      destroy: () => returnFn.forEach((fn) => fn()),
    };
  }
</script>

{#if isOpen}
  <div class="modal" use:modalAction tabindex="0">
    <div class="content-wrapper" transition:scale>
      <slot />
    </div>
  </div>
{/if}

<style lang="scss">
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    opacity: 1;

    &:not(:focus-within) {
      transition: opacity 0.1ms;
      opacity: 0.99;
    }

    &:focus {
      outline: none;
    }
  }
  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fdfefe;
    z-index: 100;
    border-radius: 1rem;
    overflow: hidden;
  }
</style>
