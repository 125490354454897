/**
 * Function to display last modification text
 * @param lastUpdate
 */
import {formatDuration} from "../../../helpers/formatDuration";

export const timeSince = (lastUpdate: number) => {
  const todayDate = Date.parse(new Date().toString());
  const seconds = Math.floor((todayDate - lastUpdate) / 1000);

  return formatDuration(seconds) + " ago"
};