/**
 * NeedController.ts
 *
 *
 * Desc: Need controller
 *
 *
 *  Author: Guilhem BERTHALON, Nicolas Galois, Dmytro Teliakovskyi
 *
 */

// Store
import {get} from 'svelte/store';
import {needExtendedListStore} from '../infrastracture/store/needExtendedListStore';
// Types
import type {NeedExtended as Need} from '../domain/models/types/Need';
import type {ApplicationBoard} from '../domain/models/types/ApplicationBoard';
import type {Application} from '../../../models/Application';
// Domain
import {NeedService} from '../domain/services/NeedService';
// Infrastructure
import {needRepository} from '../infrastracture/api/NeedRepository';
// Application
import {updateApplication} from './ApplicationController';
import {updateNeedListCount} from '../../dashboard/application/NeedListController';
import {
    popToHistoryOfCandidate,
    pushToHistoryOfCandidate,
    updateRefusedReason,
} from '../../candidate/application/CandidateController';
import {currentNeedStore} from "../infrastracture/store/currentNeedStore";
import {getRefusedReasons} from "./StagesController";
import {navigate} from "svelte-navigator";
import type {Error} from "../../error/domain/models/types/Error";
import {DefaultError} from "../../../constants/Errors";
import {getMsalUser} from "../../authentication/application/MsalController";

const needService = new NeedService(needRepository);

/**
 * Function to load need into store
 * we have 2 stores need extended list store and current need store
 * current need store is the one that is used by every component that need it
 * we want to be able to navigate during any need request
 * @param needId
 */
export const loadNeed = async (needId: string) => {
    let need = get(needExtendedListStore).find((currentNeedStore) => currentNeedStore.id === needId);

    if (!need) {
        try {
            need = await needService.get(needId)
            needExtendedListStore.update((currentNeedStore) => {
                return [...currentNeedStore, need as Need]
            })
        } catch (e) {
            // if we send only {state: { error: e }} we loose e.response.data which are our custom errors from the back
            const error: Error = (
                e.response.data.status? e.response.data: DefaultError.response.data
            )
            navigate('/err', {state: {error: {response: {data: error}}}})
            throw e
        }
    }
    // update current need store
    setCurrentNeed(need)
};

/**
 * function to set the current need only if we are at the same location we ask for
 * @param need
 */
const setCurrentNeed = (need) => {
    // The need id took from the local url of the client
    const windowNeedId = window.location.toString().split('/')[4]

    // We do not want to set the current need we are no longer at
    // without that line if you navigate during an other request of need you will see the displayed need being replaced by the longest request
    // because current need store only need to be replaced if we are at the need location we asked for
    if (windowNeedId === need.id) {
        currentNeedStore.set(need)
    }
}

/**
 * Function to handle the update of one candidate in the store and in CRM
 * @param candidateApplication
 * @param droppedBoard
 * @param note
 * @param availability
 * @param refusedReasonCode?
 */
export const handleCandidateMoved = async (
    candidateApplication: Application, droppedBoard: ApplicationBoard,
    note?: string, availability?: string, refusedReasonCode?: string
) => {
    // Get current need
    const need = get(currentNeedStore)

    // Find candidate that has moved in current need
    const movedCandidate = <Application>need.applications.find((application) => application.id === candidateApplication.id);

    // update need list in dashboard
    updateNeedListCount(movedCandidate.status, droppedBoard.name, need.id, new Date().toString());

    // Change status of candidate
    movedCandidate.status = droppedBoard.name;
    movedCandidate.activeStage = droppedBoard.name;

    // update refused reason in application
    if (refusedReasonCode) {
        // Change state to inactive front side
        movedCandidate.state = "Inactive"

        const refusedReasons = await getRefusedReasons()
        if (refusedReasons) {
            const refusedReason = refusedReasons.filter(reason => {
                return reason.Value === refusedReasonCode
            })[0]
            updateRefusedReason(candidateApplication.id, refusedReason)
        }
    }

    // update current need store
    setCurrentNeed(need)

    // Get current user
    const msalUser = await getMsalUser()

    // Update history of candidate in candidates store
    pushToHistoryOfCandidate(candidateApplication.id, {
        name: droppedBoard.name,
        date: new Date(),
        note: note,
        status: "Open",
        modifiedBy: msalUser.account.name,
        availability: availability
    });

    // request on API
    updateApplication(movedCandidate, note, availability, refusedReasonCode)
        .catch(() => {
            // Undo counting in dashboard
            updateNeedListCount(droppedBoard.name, candidateApplication.status, need.id, new Date().toString());

            // Undo history push
            popToHistoryOfCandidate(candidateApplication.id);

            // Undo status in candidate
            movedCandidate.status = candidateApplication.status;
            movedCandidate.activeStage = candidateApplication.activeStage;

            // update current need store
            setCurrentNeed(need)
        });
};
