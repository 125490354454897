/**
 * function to format a duration in seconds to years, months, days, hours, min or seconds
 * @param seconds
 */
export const formatDuration = (seconds: number) => {
    let displayedText = '';

    let interval = Math.floor(seconds / 31536000);
    if (interval > 1) {
        displayedText = `${interval}y`;
        return displayedText;
    }

    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
        displayedText = `${interval}m`;
        return displayedText;
    }

    interval = Math.floor(seconds / 86400);
    if (interval >= 1) {
        displayedText = `${interval}d`;
        return displayedText;
    }

    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
        displayedText = `${interval}h`;
        return displayedText;
    }

    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
        displayedText = `${interval}min`;
        return displayedText;
    }
    return 'a few seconds';
}