<script lang="ts">
  import { navigate } from 'svelte-navigator';
  // Types
  import type { Career } from '../../../../models/Career';
  // Components
  import MdPerson from 'svelte-icons/md/MdPerson.svelte';
  import FaEllipsisV from 'svelte-icons/fa/FaEllipsisV.svelte';
  import Button from '../../../../lib/Button.svelte';
  import { CVError } from '../../../../constants/Errors';

  // Imported variables
  export let career: Career;
  export let applicationId: string;
  export let needType: string;
  // if isActive, this candidate can move between board
  // if not isActive, the state of candidate is Inactive and can be resigned or definitely accepted depending on his current board
  export let isInactive: boolean;
  export let isResigned: boolean;

  function openCV() {
    if (career.CVLink) {
      window.open(career.CVLink, '_blank');
    } else {
      navigate('/err', { state: { error: { response: { data: CVError.response.data } } } });
    }
  }

  function openCandidatePage() {
    navigate(`/candidate/${applicationId}`, { state: { needType } });
  }
</script>

<li class="card" class:inactive={isInactive} class:resigned={isResigned} draggable={!isInactive} on:dragstart>
  <picture class="circle">
    <FaEllipsisV />
    <FaEllipsisV />
  </picture>

  <div class="avatar">
    <picture>
      <MdPerson />
    </picture>
    <span class="name">{career.trigram}</span>
  </div>

  <div class="details">
    <header>{career.name}</header>
    {#if career.nbYearsXP}
      <span>{career.nbYearsXP} year of experience</span>
    {:else}
      <span />
    {/if}
    <div class="button__group">
      <span class="button">
        <Button type={'secondary--outline'} on:click={openCV}>CV</Button>
      </span>
      <span class="button">
        <Button type={'secondary'} on:click={openCandidatePage}>View</Button>
      </span>
    </div>
  </div>
</li>

<style lang="scss">
  .card {
    position: relative;
    min-height: 15rem;
    cursor: grab;
    list-style: none;
    display: flex;
    align-items: center;
    background: white;
    border-radius: 1rem;
    border-left: 4px solid transparent;
    transition: all 0.2s;

    &:hover,
    &:active {
      border-left: 4px solid #3fb7f1;
    }

    &:active {
      cursor: grabbing;
    }

    &:hover .circle,
    &:active .circle {
      color: #3fb7f1;
    }
  }

  .inactive {
    background-color: #eee;
    cursor: no-drop;
  }

  .resigned {
    background-color: white;
    opacity: 1;

    &::after {
      content: 'Resigned';
      position: absolute;
      top: 50%;
      left: 50%;
      color: #ff6239;
      font-size: 3.2rem;
      font-weight: bold;
      -webkit-text-stroke: 1px white;
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
      transform: translate(-50%, -50%) rotate(345deg);
    }
  }

  .circle {
    display: flex;
    width: 1rem;
    margin-left: 0.6rem;
  }

  .avatar {
    flex: 0 0 40%;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    align-items: center;
  }

  picture {
    width: 4rem;
    height: 4rem;
  }

  .name {
    font-size: 1.6rem;
  }

  .details {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  header {
    font-size: 1.6rem;
    font-weight: bold;
  }

  span {
    display: inline-block;
    font-size: 1.4rem;
  }

  .button__group {
    padding-top: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .button {
    white-space: nowrap;
    align-self: flex-end;
  }
</style>
