/**
 * Function to display the salary based on typeOfContract, expectedSalary
 * @param typeOfContract can be freelance or permanent
 * @param expectedSalary if permanent
 * @param dayRate if freelance
 */
import type { RefusedReason } from '../../../models/RefusedReason';
import type { Salary } from '../../../models/Salary';

export const displaySalary = (typeOfContract: string, expectedSalary: Salary, dayRate: Salary) => {
  return typeOfContract === 'Freelance' ? displayDayRate(dayRate) : displayYearSalary(expectedSalary);
};

/**
 * private function to display year salary if null
 * @param expectedSalary
 */
const displayYearSalary = (expectedSalary?: Salary) => {
  return expectedSalary ? `💰 Expected salary: ${expectedSalary.amount}${expectedSalary.unit} per year` : '';
};

/**
 * private function to display day rate if null
 * @param dayRate
 */
const displayDayRate = (dayRate?: Salary) => {
  return dayRate ? `💰 Expected salary: ${dayRate.amount} ${dayRate.unit} per day` : '';
};

/**
 * private function to display a date
 * @param date
 */
export const displayDate = (date: Date) => {
  return `${date.toLocaleDateString()} - ${date.toLocaleTimeString('it-IT').slice(0, 5)}`;
};

/**
 * private function to display note if null
 * @param note
 */
export const displayNote = (note?: string) => {
  return note ? `${note}` : '';
};

/**
 * private function to display year availability if null
 * @param availability
 */
export const displayAvailability = (availability?: string) => {
  return availability ? 'Availability: ' + availability : '';
};

// export const displayDuration = (statusCode: string, duration: number) => {
//   return statusCode === 'Completed' ? 'duration: ' + displayDurationHelper(duration) : 'Step on going';
// };

// const displayDurationHelper = (duration: number) => {
//   return duration > 0 ? duration + ' min' : 'less than a minute';
// };

/**
 * private function to display modified by if null
 * @param name
 */
export const displayModifiedBy = (name: string) => {
  return name ? 'Modified by ' + name : '';
};

/**
 * private function to display refused reason if null
 * @param refusedReason
 */
export const displayRefusedReason = (refusedReason?: RefusedReason) => {
  return refusedReason ? `Reason: ${refusedReason.Label} - ${refusedReason.Description}` : '';
};
