/**
 * CandidateRepository.ts
 *
 * Desc: implementation of interface for candidate route
 */
import { ApiRepository } from '../../../../helpers/ApiRepository';
import type { CandidateRepositoryInterface } from '../../domain/interfaces/CandidateRepositoryInterface';

export class CandidateRepository implements CandidateRepositoryInterface {
  /**
   * Function to get candidate from API
   * @param applicationId
   */
  async get(applicationId: string) {
    try {
      const { data } = await ApiRepository.get(`/candidate?applicationId=${applicationId}`);
      return data;
    } catch (err) {
      throw err;
    }
  }
}

export const candidateRepository = new CandidateRepository();
