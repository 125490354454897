/**
 * CandidateService.ts
 *
 * Desc: Service of candidate API
 */
import type { CandidateRepositoryInterface } from '../interfaces/CandidateRepositoryInterface';
import type {ApplicationExtended as Application} from "../types/Application";

export class CandidateService {
  constructor(protected candidatureRepository: CandidateRepositoryInterface) {}

  /**
   * function to get one candidate by its applicationId
   * @param applicationId
   */
  async get(applicationId: string): Promise<Application> {
    return this.candidatureRepository.get(applicationId);
  }
}
