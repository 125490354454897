/**
 * MsalService.ts
 *
 * Desc: service of microsoft
 */

import type {MsalRepositoryInterface} from "../models/interfaces/MsalRepositoryInterface";
import {MsalRepository} from "../../infrastructure/MsalRepository";
import type {MsalUser} from "../models/types/MsalUser";

export class MsalService {
    private msalRepository: MsalRepositoryInterface

    /**
     * this constructor does not need a repository to be create because of circular dependency fixed
     */
    constructor() {
        this.msalRepository = new MsalRepository()
    }

    /**
     * get user from login popup
     */
    async getUser(): Promise<MsalUser> {
        return this.msalRepository.getUser();
    }

    /**
     * get refresh token
     */
    async getIdToken(): Promise<string> {
        return this.msalRepository.getRefreshToken();
    }

    /**
     * logout from microsoft account
     */
    logout() {
        return this.msalRepository.logout();
    }
}