<script lang="ts">
  // Application
  import { login } from '../../application/UserController';
  import { bertekLogo } from '../../../../constants/images';
</script>

<ul>
  <aside>
    <h1>Welcome to <span><img src={bertekLogo} alt="PopIT" /></span></h1>
    <h3>Please login to your account</h3>
    <button on:click={() => login()}>Login now</button>
  </aside>
  <li />
  <li />
  <li />
  <li />
  <li />
  <li />
  <li />
</ul>

<style lang="scss">
  aside {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h1 {
    display: flex;
    align-items: center;
    font-size: 3.8rem;
    font-weight: bold;
  }
  img {
    margin-left: -0.4rem;
    margin-top: 0.4rem;
    display: block;
    height: 5rem;
  }
  h3 {
    font-size: 2.4rem;
    font-weight: 300;
  }

  ul {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  li {
    position: absolute;
    width: 5rem;
    height: 5rem;
    background: #37f489;
    border-radius: 3px;
    top: 100%;
    left: 25%;
    z-index: -1;
    overflow: hidden;
    animation: move 10s linear infinite;

    &:nth-last-of-type(2) {
      left: 7rem;
      animation-delay: 3.5s;
      height: 1.5rem;
      width: 1.5rem;
    }

    &:nth-last-of-type(3) {
      left: 14rem;
      animation-delay: 3s;
    }

    &:nth-last-of-type(4) {
      left: 21rem;
      animation-delay: 5.5s;
    }

    &:nth-last-of-type(5) {
      left: 28rem;
      animation-delay: 1.8s;
      height: 6.5rem;
      width: 6.5rem;
    }

    &:nth-last-of-type(6) {
      left: 14rem;
      animation-delay: 6.8s;
      height: 2.5rem;
      width: 2.5rem;
    }

    &:nth-last-of-type(7) {
      left: 28rem;
      animation-delay: 5s;
      height: 3.5rem;
      width: 3.5rem;
    }
  }

  @keyframes move {
    to {
      top: -5rem;
      transform: rotate(360deg);
    }
  }

  button {
    &,
    &:link,
    &:visited {
      position: relative;
      display: inline-block;
      cursor: pointer;
      margin-top: 2rem;
      padding: 1.5rem 4rem;

      background-color: #37f487;
      color: white;

      border: none;
      border-radius: 10rem;

      font-size: 1.8rem;
      text-decoration: none;

      transition: all 0.2s;
    }

    &:hover {
      transform: translateY(-1px);
      box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);

      &::after {
        transform: scaleX(1.4) scaleY(1.6);
        opacity: 0;
      }
    }

    &:active,
    &:focus {
      outline: none;
      transform: translateY(-1px);
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
    }

    &::after {
      content: '';
      display: inline-block;
      height: 100%;
      width: 100%;
      border-radius: 10rem;
      position: absolute;
      background-color: #37f487;
      top: 0;
      left: 0;
      z-index: -1;
      transition: all 0.4s;
    }
  }
</style>
