<script lang="ts">
  // Types
  import type { Status } from '../../domain/models/types/Status';
  // Imported variables
  export let status: Status;
  export let maxCount: number = 0;
</script>

<div>
  <label for={status.name}>{status.name}</label>
  <progress id={status.name} value={status.count} max={maxCount} class={status.name.toLocaleLowerCase()} />
  <span>{status.count}</span>
</div>

<style lang="scss">
  div {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  label {
    flex: 0 0 25%;
    font-size: 1.6rem;
  }
  progress {
    flex: 1 1 auto;
    display: flex;
    height: 2rem;

    border-radius: 0.5rem;
    animation: load 3s normal forwards;
  }
  progress::-moz-progress-bar {
    border-radius: 0.5rem;
    background-color: #3fb7f1;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  }
  progress::-webkit-progress-bar {
    border-radius: 0.5rem;
    background-color: #f8f8f8;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  }
  progress::-webkit-progress-value {
    border-radius: 0.5rem;
    background-color: #3fb7f1;
  }
  .accepted::-webkit-progress-value {
    background-color: #37f489;
  }
  .refused::-webkit-progress-value,
  .resigned::-webkit-progress-value {
    background-color: #ff6239;
  }
  .accepted::-moz-progress-bar {
    background-color: #37f489;
  }
  .refused::-moz-progress-bar,
  .resigned::-moz-progress-bar {
    background-color: #ff6239;
  }
  span {
    font-size: 1.4rem;
  }
</style>
